import _ from 'lodash';
import React from 'react';
import components, { Layout2 } from '../components/index';

export default class Advanced2 extends React.Component {
  render() {
    return (
      <Layout2 {...this.props}>
        {(!_.get(this.props, 'pageContext.frontmatter.hide_title')) &&
          <header className="post-header inner-sm">
            <h1 className="post-title underline">{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
          </header>
        }
        {_.map(_.get(this.props, 'pageContext.frontmatter.sections'), (section, section_idx) => {
          let component = _.upperFirst(_.camelCase(_.get(section, 'type')));
          let Component = components[component];
          return (
            <Component key={section_idx} {...this.props} section={section} site={this.props.pageContext.site} />
          )
        })}

      </Layout2>
    );
  }
}
